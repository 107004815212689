<template>
  <div :class="!isMobileDevice ? 'login-container' : 'login-container-mobile'">
    <div class="top" v-if="!isMobileDevice">
      <img
        src="../../assets/login_register/pc/白色公司logo.png"
        style="width: 272px"
        alt=""
        @click="$router.push('/')"
      />
      <!-- <ul>
        <li @click="$router.push('/migrate')">迁徙记忆</li>
        <li @click="$router.push('/drilling')">大数据分析</li>
        <li class="last" @click="$router.push('/')">台湾文献数据库</li>
        <img
          src="../../assets/login_register/pc/寻根logo.png"
          style="width: 24px"
          alt=""
        />
      </ul> -->
    </div>
    <!-- pc -->
    <div class="form-box" v-if="!isMobileDevice">
      <div class="login-form">
        <img
          src="../../assets/login_register/pc/蓝色公司logo.png"
          alt=""
          class="logo"
        />
        <div class="register-box">
          <h4
            @click="changeType('emailCode')"
            :class="{ active: optype === 'emailCode' }"
          >
            <em>邮箱注</em>册
          </h4>
          <h4
            @click="changeType('phoneCode')"
            :class="{ active: optype === 'phoneCode' }"
          >
            <em>手机号注</em>册
          </h4>
        </div>
        <!-- 注册表单 -->
        <el-form
          ref="phoneRegisterForm"
          :model="form"
          :rules="registerRules"
          class="form"
          v-show="optype === 'phoneCode'"
        >
          <!-- 手机号 -->
          <el-form-item prop="telephone">
            <el-input
              placeholder="请输入手机号"
              v-model="form.telephone"
              name="telephone"
            >
              <img
                src="../../assets/login_register/pc/手机图标.png"
                slot="prefix"
                alt=""
              />
            </el-input>
          </el-form-item>

          <!-- 图形验证码 -->
          <el-form-item prop="picVCode">
            <el-input
              v-model="form.picVCode"
              placeholder="请输入图形验证码"
              name="picVCode"
            >
              <img
                src="../../assets/login_register/pc/锁图标.png"
                slot="prefix"
                alt=""
              />
              <el-image
                slot="suffix"
                :src="vcodeUrl"
                style="height: 100%"
                @click.self="getVcode"
              />
            </el-input>
          </el-form-item>

          <!-- 手机验证码 -->
          <el-form-item prop="vCode">
            <el-input
              v-model="form.vCode"
              type="text"
              name="vCode"
              tabindex="2"
              auto-complete="on"
              placeholder="请输入手机验证码"
            >
              <img
                src="../../assets/login_register/pc/验证码图标.png"
                slot="prefix"
                alt=""
              />
              <span
                v-if="isPhoneCodeShow"
                slot="suffix"
                class="phone-code"
                @click="getPhoneVcode"
                >获取验证码</span
              >
              <span v-else slot="suffix" class="phone-code"
                >{{ countDown }}s后重新获取</span
              >
            </el-input>
          </el-form-item>

          <el-form-item style="text-align: center">
            <span style="font-size: 14px; text-align: end; display: block">
              手机号注册，初始密码为手机号后6位！
            </span>
            <el-button type="primary" @click.native.prevent="handleRegister"
              >注 册</el-button
            >
          </el-form-item>
          <el-form-item style="text-align: right">
            <span
              >已有账号？<a
                href="javascript:;"
                @click="$router.push({ name: 'Login' })"
                style="text-decoration: underline"
                >立即登录</a
              ></span
            >
          </el-form-item>
          <div
            style="
              position: absolute;
              bottom: 10px;
              left: 50%;
              transform: translateX(-50%);
            "
          >
            <el-link @click="JumpPrivacy" type="primary"
              >阅读并接受《隐私政策》</el-link
            >
          </div>
        </el-form>

        <!-- 邮箱注册表单 -->
        <el-form
          ref="emailRegisterForm"
          :model="emailForm"
          :rules="emailRules"
          class="form"
          v-show="optype === 'emailCode'"
        >
          <!-- 邮箱 -->
          <el-form-item prop="email">
            <el-input
              placeholder="请输入邮箱号"
              v-model="emailForm.email"
              name="email"
            >
              <img
                src="../../assets/login_register/pc/验证码图标.png"
                slot="prefix"
                alt=""
              />
            </el-input>
          </el-form-item>

          <!-- 图形验证码 -->
          <el-form-item prop="code">
            <el-input
              v-model="emailForm.code"
              placeholder="请输入图形验证码"
              name="code"
            >
              <img
                src="../../assets/login_register/pc/锁图标.png"
                slot="prefix"
                alt=""
              />
              <el-image
                slot="suffix"
                :src="vcodeUrl"
                style="height: 100%"
                @click.self="getVcode"
              />
            </el-input>
          </el-form-item>

          <!-- 邮箱验证码 -->
          <el-form-item prop="vCode">
            <el-input
              v-model="emailForm.vCode"
              type="text"
              name="vCode"
              tabindex="2"
              auto-complete="on"
              placeholder="请输入邮箱验证码"
            >
              <img
                src="../../assets/login_register/pc/验证码图标.png"
                slot="prefix"
                alt=""
              />
              <span
                v-if="isEmailCodeShow"
                slot="suffix"
                class="phone-code"
                @click="getEmailVCode"
                >获取验证码</span
              >
              <span v-else slot="suffix" class="phone-code"
                >{{ countDown }}s后重新获取</span
              >
            </el-input>
          </el-form-item>

          <!-- 密码 -->
          <el-form-item prop="passWord">
            <el-input
              v-model="emailForm.passWord"
              type="password"
              placeholder="请输入密码"
            >
              <img
                src="../../assets/login_register/pc/锁图标.png"
                slot="prefix"
                alt=""
              />
            </el-input>
          </el-form-item>

          <!-- 确认密码 -->
          <el-form-item prop="confirmPassWord">
            <el-input
              v-model="emailForm.confirmPassWord"
              type="password"
              placeholder="请输入确认密码"
            >
              <img
                src="../../assets/login_register/pc/锁图标.png"
                slot="prefix"
                alt=""
              />
            </el-input>
          </el-form-item>

          <el-form-item style="text-align: center">
            <el-button type="primary" @click.native.prevent="handleRegister"
              >注 册</el-button
            >
          </el-form-item>
          <el-form-item style="text-align: right">
            <span
              >已有账号？<a
                href="javascript:;"
                @click="$router.push({ name: 'Login' })"
                style="text-decoration: underline"
                >立即登录</a
              ></span
            >
          </el-form-item>
          <div
            style="
              position: absolute;
              bottom: 10px;
              left: 50%;
              transform: translateX(-50%);
            "
          >
            <el-link @click="JumpPrivacy" type="primary"
              >阅读并接受《隐私政策》</el-link
            >
          </div>
        </el-form>
      </div>
    </div>
    <!-- mobile -->
    <div class="form-mobile" v-else>
      <img
        src="../../assets/login_register/mobile/蓝色logo.png"
        style="width: 269px"
        alt=""
      />
      <div class="register-box">
        <h4
          @click="changeType('emailCode')"
          :class="{ active: optype === 'emailCode' }"
        >
          <em>邮箱注</em>册
        </h4>
        <h4
          @click="changeType('phoneCode')"
          :class="{ active: optype === 'phoneCode' }"
        >
          <em>手机号注</em>册
        </h4>
      </div>
      <el-form
        ref="phoneRegisterForm"
        :model="form"
        class="form"
        :rules="registerRules"
        v-show="optype === 'phoneCode'"
      >
        <!-- 手机号 -->
        <el-form-item prop="telephone">
          <el-input placeholder="请输入手机号" v-model="form.telephone">
            <img
              src="../../assets/login_register/mobile/手机图标.png"
              slot="prefix"
              alt=""
              style="width: 18px"
            />
          </el-input>
        </el-form-item>

        <!-- 图形验证码 -->
        <el-form-item prop="picVCode">
          <el-input
            v-model="form.picVCode"
            placeholder="请输入图形验证码"
            name="picVCode"
          >
            <img
              src="../../assets/login_register/mobile/锁图标.png"
              slot="prefix"
              alt=""
              style="width: 18px"
            />
            <el-image
              slot="suffix"
              :src="vcodeUrl"
              style="height: 100%"
              @click.self="getVcode"
            />
          </el-input>
        </el-form-item>

        <!-- 手机验证码 -->
        <el-form-item prop="vCode">
          <el-input
            v-model="form.vCode"
            type="text"
            name="vCode"
            tabindex="2"
            auto-complete="on"
            placeholder="请输入手机验证码"
          >
            <img
              src="../../assets/login_register/mobile/验证码图标.png"
              slot="prefix"
              alt=""
              style="width: 18px"
            />
            <span
              v-if="isPhoneCodeShow"
              slot="suffix"
              class="phone-code"
              @click="getPhoneVcode"
              >获取验证码</span
            >
            <span v-else slot="suffix" class="phone-code"
              >{{ countDown }}s后重新获取</span
            >
          </el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <span style="font-size: 14px; text-align: end; display: block">
            手机号注册，初始密码为手机号后6位！
          </span>
          <el-button
            type="primary"
            style="width: 100%; border-radius: 10px"
            @click.native.prevent="handleRegister"
            v-loading="loading"
            >注 册</el-button
          >
        </el-form-item>
        <el-form-item style="text-align: right">
          <span
            >已有账号？<a
              href="javascript:;"
              @click="$router.push({ name: 'Login' })"
              style="text-decoration: underline"
              >立即登录</a
            ></span
          >
        </el-form-item>
      </el-form>
      <!-- 邮箱注册表单 -->
      <el-form
        ref="emailRegisterForm"
        :model="emailForm"
        :rules="emailRules"
        class="form"
        v-show="optype === 'emailCode'"
      >
        <!-- 邮箱 -->
        <el-form-item prop="email">
          <el-input
            placeholder="请输入邮箱号"
            v-model="emailForm.email"
            name="email"
          >
            <img
              src="../../assets/login_register/pc/验证码图标.png"
              slot="prefix"
              alt=""
            />
          </el-input>
        </el-form-item>

        <!-- 图形验证码 -->
        <el-form-item prop="code">
          <el-input
            v-model="emailForm.code"
            placeholder="请输入图形验证码"
            name="code"
          >
            <img
              src="../../assets/login_register/pc/锁图标.png"
              slot="prefix"
              alt=""
            />
            <el-image
              slot="suffix"
              :src="vcodeUrl"
              style="height: 100%"
              @click.self="getVcode"
            />
          </el-input>
        </el-form-item>

        <!-- 邮箱验证码 -->
        <el-form-item prop="vCode">
          <el-input
            v-model="emailForm.vCode"
            type="text"
            name="vCode"
            tabindex="2"
            auto-complete="on"
            placeholder="请输入邮箱验证码"
          >
            <img
              src="../../assets/login_register/pc/验证码图标.png"
              slot="prefix"
              alt=""
            />
            <span
              v-if="isEmailCodeShow"
              slot="suffix"
              class="phone-code"
              @click="getEmailVCode"
              >获取验证码</span
            >
            <span v-else slot="suffix" class="phone-code"
              >{{ countDown }}s后重新获取</span
            >
          </el-input>
        </el-form-item>

        <!-- 密码 -->
        <el-form-item prop="passWord">
          <el-input
            v-model="emailForm.passWord"
            type="password"
            placeholder="请输入密码"
          >
            <img
              src="../../assets/login_register/pc/锁图标.png"
              slot="prefix"
              alt=""
            />
          </el-input>
        </el-form-item>

        <!-- 确认密码 -->
        <el-form-item prop="confirmPassWord">
          <el-input
            v-model="emailForm.confirmPassWord"
            type="password"
            placeholder="请输入确认密码"
          >
            <img
              src="../../assets/login_register/pc/锁图标.png"
              slot="prefix"
              alt=""
            />
          </el-input>
        </el-form-item>

        <el-form-item style="text-align: center">
          <el-button
            type="primary"
            style="width: 100%; border-radius: 10px"
            @click.native.prevent="handleRegister"
            v-loading="loading"
            >注 册</el-button
          >
        </el-form-item>
        <el-form-item style="text-align: right; margin-bottom: 0px">
          <span
            >已有账号？<a
              href="javascript:;"
              @click="$router.push({ name: 'Login' })"
              style="text-decoration: underline"
              >立即登录</a
            ></span
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { register, getPhoneCode, sendEmail, emailReg } from "@/api/user.js";
import {
  validMobile,
  validEmail,
  validConfirmPassword,
} from "@/utils/validate.js";
export default {
  data() {
    const validateMobile = (rule, value, callback) => {
      validMobile(value) ? callback() : callback(new Error("手机号格式不正确"));
    };
    const validateEmail = (rule, value, callback) => {
      validEmail(value) ? callback() : callback(new Error("邮箱格式不正确"));
    };
    const validateConfirmPassword = (rule, value, callback) => {
      validConfirmPassword(value, this.emailForm.passWord)
        ? callback()
        : callback(new Error("邮箱格式不正确"));
    };
    return {
      optype: "emailCode",
      form: {
        telephone: "",
        passWord: "",
        picVCode: "",
        vCode: "",
        confirmPassWord: "",
        taiWanAncestralHome: "",
        surname: "",
      },
      emailForm: {
        passWord: "",
        confirmPassWord: "",
        vCode: "", //邮箱验证码
        email: "",
        code: "",
      },
      emailRules: {
        email: [
          { required: true, trigger: "blur", message: "邮箱号不能为空" },
          {
            validator: validateEmail,
            trigger: "blur",
            message: "邮箱号格式不正确",
          },
        ],
        code: [
          { required: true, target: "blur", message: "图形验证码不能为空" },
        ],
        vCode: [
          { required: true, target: "blur", message: "邮箱验证码不能为空" },
        ],
        passWord: [
          { required: true, target: "blur", message: "密码不能为空" },
          { min: 6, max: 12, message: "密码长度需为6-12位", trigger: "blur" },
        ],
        confirmPassWord: [
          { required: true, target: "blur", message: "确认不能为空" },
          {
            validator: validateConfirmPassword,
            trigger: "blur",
            message: "两次密码不一致",
          },
        ],
      },
      registerRules: {
        telephone: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
          {
            validator: validateMobile,
            trigger: "blur",
            message: "手机号格式不正确",
          },
        ],
        picVCode: [
          { required: true, target: "blur", message: "图形验证码不能为空" },
        ],
        vCode: [
          { required: true, target: "blur", message: "手机验证码不能为空" },
        ],
      },

      loading: false,
      vcodeUrl: "", // 图片验证码
      isPhoneCodeShow: true,
      isEmailCodeShow: true,
      countDown: 60,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getVcode();
  },
  computed: {
    isMobileDevice() {
      return navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      );
    },
    // isIpadFun() {
    //   var ua = window.navigator.userAgent;
    //   var IsIPad = false;
    //   if (/ipad/i.test(ua)) {
    //     IsIPad = true;
    //   }
    //   // iPad from IOS13
    //   var macApp = ua.match(/Macintosh/i) != null;
    //   if (macApp) {
    //     // need to distinguish between Macbook and iPad
    //     var canvas = document.createElement("canvas");
    //     if (canvas != null) {
    //       var context =
    //         canvas.getContext("webgl") ||
    //         canvas.getContext("experimental-webgl");
    //       if (context) {
    //         var info = context.getExtension("WEBGL_debug_renderer_info");
    //         if (info) {
    //           var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
    //           if (renderer.indexOf("Apple") != -1) IsIPad = true;
    //         }
    //       }
    //     }
    //   }
    //   return IsIPad;
    // },
  },
  methods: {
    changeType(type) {
      this.optype = type;
      this.getVcode();
      clearInterval(this.timer);
      this.isPhoneCodeShow = true;
      this.isEmailCodeShow = true;
      this.countDown = 60;
      this.form = {
        telephone: "",
        passWord: "",
        picVCode: "",
        vCode: "",
        confirmPassWord: "",
        taiWanAncestralHome: "",
        surname: "",
      };
      this.emailForm = {
        passWord: "",
        confirmPassWord: "",
        vCode: "", //邮箱验证码
        email: "",
        code: "",
      };
    },
    // 获取手机验证码
    async getPhoneVcode() {
      if (!this.form.telephone) {
        return this.$message.error("请先输入手机号");
      }
      if (!this.form.picVCode) {
        return this.$message.error("请先输入图形验证码");
      }
      this.isPhoneCodeShow = false;
      try {
        await getPhoneCode({
          vCode: this.form.picVCode,
          telephone: this.form.telephone,
        });
        this.timer = setInterval(() => {
          if (this.countDown <= 0) {
            this.countDown = 60;
            this.isPhoneCodeShow = true;
            clearInterval(this.timer);
            return;
          }
          this.countDown -= 1;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.countDown = 60;
        this.isPhoneCodeShow = true;
        this.getVcode();
        clearInterval(this.timer);
      } finally {
      }
    },
    async getEmailVCode() {
      if (!this.emailForm.email || !this.emailForm.code)
        return this.$message.info("邮箱或图形验证码不能为空！");
      this.isEmailCodeShow = false;
      try {
        await sendEmail({
          code: this.emailForm.code,
          eamilAddress: this.emailForm.email,
        });
        this.timer = setInterval(() => {
          if (this.countDown <= 0) {
            this.countDown = 60;
            this.isEmailCodeShow = true;
            clearInterval(this.timer);
            return;
          }
          this.countDown -= 1;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.countDown = 60;
        this.isEmailCodeShow = true;
        this.getVcode();
        clearInterval(this.timer);
      } finally {
      }
    },
    // 获取图片验证码
    getVcode() {
      if (this.optype === "phoneCode") {
        this.vcodeUrl =
          "/api/app/user/vcode?optype=phoneCode&&random=" + Math.random() + "";
      } else {
        this.vcodeUrl =
          "/api/app/user/vcode?optype=emailCode&&random=" + Math.random() + "";
      }
    },
    JumpPrivacy() {
      let routeUrl = this.$router.resolve({
        path: "/privacy",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 注册
    handleRegister() {
      let refsName =
        this.optype === "phoneCode" ? "phoneRegisterForm" : "emailRegisterForm";
      this.$refs[refsName].validate(async (valid) => {
        if (valid) {
          if (this.optype === "phoneCode") {
            this.form.passWord = this.form.confirmPassWord =
              this.form.telephone.slice(-6);
            try {
              this.loading = true;
              const res = await register({
                ...this.form,
                userName: this.form.telephone,
              });
              // this.$router.push({ name: "Login" });
              await this.$store.dispatch("user/login", {
                userName: this.form.telephone,
                password: this.form.passWord,
                loginFromType: this.isMobileDevice ? 2 : 1,
              });
              this.$router.push("/robotmigrate");
              this.$notify.success({
                message: "登录成功！",
              });
            } catch (err) {
              this.$message.error(err.response.data.error.message);
            } finally {
              this.loading = false;
            }
          } else {
            try {
              this.loading = true;
              await emailReg(this.emailForm);
              this.$notify.success("注册成功！");
              this.$router.push({ name: "Login" });
            } catch (e) {
              console.log(e);
            } finally {
              this.loading = false;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/login_register/pc/大背景图.jpg");
  background-size: 100% 100%;

  .top {
    width: 100%;
    padding: 20px 120px;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }

    ul {
      display: flex;
      align-items: center;
      li {
        padding: 5px 40px;
        color: #fff;
        border-right: 1px solid #2864db;
        cursor: pointer;
      }

      .last {
        border-right: none;
      }
    }
  }

  .form-box {
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translateX(-50%);
    border-radius: 21px;
    width: 1300px;
    height: 740px;
    background: url("../../assets/login_register/pc/背景框.png") left;

    .login-form {
      width: 570px;
      height: 695px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 70px 63px 0;
      text-align: center;

      .register-box {
        display: flex;

        h4 {
          text-align: left;
          margin: 70px 20px 0;
          font-size: 16px;
          color: #000;
          font-weight: 500;
          padding-bottom: 15px;
          cursor: pointer;
          em {
            font-style: normal;
            padding-bottom: 15px;
            border-bottom: 2px solid #000;
          }
        }
        h4:hover {
          color: #2864db;

          em {
            border-bottom: 2px solid #2864db;
          }
        }
        .active {
          color: #2864db;
          font-size: 18px;
          em {
            border-bottom: 2px solid #2864db;
          }
        }
      }

      .form {
        margin-top: 33px;

        .phone-code {
          cursor: pointer;
          color: #2864db;
        }

        .el-form-item {
          text-align: left;
          margin-bottom: 18px;

          span {
            font-size: 16px;
            font-weight: 600;

            a {
              font-style: normal;
              color: #799ee9;
              cursor: pointer;
            }
          }
        }
        ::v-deep .el-input__inner {
          height: 45px;
          border-radius: 10px;
          padding-left: 50px;
        }
        // .email {
        //   ::v-deep .el-input__inner {
        //     height: 50px;
        //   }
        // }
        ::v-deep .el-input__prefix {
          display: flex;
          align-items: center;
          margin-left: 18px;
        }
        ::v-deep .el-input__suffix,
        .el-input__suffix-inner {
          display: flex;
          align-items: center;
          margin-right: 18px;
        }
        ::v-deep .el-input .el-input__clear {
          font-size: 22px;
        }
        ::v-deep .el-input__suffix-inner {
          display: flex;
        }
        ::v-deep .el-button {
          width: 100%;
          border-radius: 10px;
          height: 50px;
          font-size: 18px;
        }
      }
    }
  }
}

//@media screen and ( max-width: 750px) {
.login-container-mobile {
  padding-top: 111px;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/login_register/mobile/背景图.jpg");
  background-size: 100% 100%;

  .form-mobile {
    margin: 0 auto;
    width: 344px;
    height: 492px;
    //background: url("../../assets/login_register/mobile/背景框.png");
    background: #fff;
    background-size: 100%;
    padding: 10px 41px;
    border-radius: 10px;

    .register-box {
      display: flex;

      h4 {
        display: inline-block;
        margin: 10px 10px 0;
        font-size: 16px;
        color: #000;
        em {
          font-style: normal;
          padding-bottom: 6px;
          border-bottom: 2px solid #000;
        }
      }
      h4:hover {
        color: #2864db;

        em {
          border-bottom: 2px solid #2864db;
        }
      }
      .active {
        color: #2864db;
        font-size: 18px;
        em {
          border-bottom: 2px solid #2864db;
        }
      }
    }

    .form {
      margin-top: 23px;
      position: relative;

      .phone-code {
        cursor: pointer;
        color: #2864db;
      }
      ::v-deep .el-form-item {
        margin-bottom: 18px;
      }

      ::v-deep .el-input__prefix {
        display: flex;
        align-items: center;
      }
      ::v-deep .el-input__suffix-inner {
        display: flex;
      }
      ::v-deep .el-input__suffix,
      .el-input__suffix-inner {
        display: flex;
        align-items: center;
      }
    }
  }
}
//}
</style>
